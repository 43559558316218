<template>

	<container container_id="players">

		<template slot="container-head">

			<div>{{ this.$store.state.players.results.length }} joueurs</div>

			<div v-if="is_phone()" class="mobile-ordering">

				<button id="sort_mobile-dir" @click="sort_mobile">{{ sorting.dir }}</button>

				<vselect
					:id="'sort-mobile'"
					:options="[
						{ value : 'estimation', label : 'EST' },
						{ value : 'avg_season', label : 'SR' },
						{ value : 'momentum', label : 'MOM' },
						{ value : 'stab', label : 'STB' },
						{ value : 'l10_avg', label : '10J' },
						{ value : 'l30_avg', label : '30J' },
						{ value : 'index_vs', label : 'Index VS' },
						{ value : 'index_def', label : 'VS -/+' },
					]"
					:select="'estimation'"
					:onchange="sort_mobile"
				/>

			</div>

		</template>

		<!-- Version desktop -->
		<template slot="container-body" v-if="!is_phone()"><table class="datas-table desktop">

			<thead>

				<tr class="thead-top" v-if="!is_phone()">

					<th class="align-center" colspan="3">JOUEUR</th>
					<th class="separator"></th>
					<th class="align-center" colspan="3">MOYENNES</th>
					<th class="separator"></th>
					<th class="align-center" colspan="4">VERSUS</th>
					<th class="separator"></th>
					<th class="align-center" colspan="6">INDICATEURS</th>
					<th class="separator"></th>

				</tr>

				<tr class="thead-main">

					<th colspan="3" class="th-buttons"><div>
						<button @click="compare = !compare" :class="{ active : compare }" title="Exclure les joueurs non sélectionnés">
							<font-awesome-icon :icon="['fa', 'people-arrows']" />
						</button>
						<button @click="available_only = !available_only" :class="{ active : available_only }" title="Exclure les joueurs non disponibles">
							<font-awesome-icon :icon="['fa', 'user-check']" />
						</button>
						<button @click="available_injury = !available_injury" :class="{ active : available_injury }" title="Exclure les joueurs OUT">
							<font-awesome-icon :icon="['fa', 'user-md']" />
						</button>
					</div></th>

					<th class="separator" v-if="!is_phone()"></th>

					<th class="sort-l10_avg average sort align-center" @click="sort( 'l10_avg' )">
						<div>10J</div>
					</th>
					<th class="sort-l30_avg average sort align-center" @click="sort( 'l30_avg' )" v-if="!is_phone()">
						<div>30J</div>
					</th>
					<th class="sort-avg_season average sort align-center" @click="sort( 'avg_season' )" v-if="!is_phone()">
						<div>SR</div>
					</th>

					<th class="separator" v-if="!is_phone()"></th>

					<th colspan="2" v-if="!is_phone()">
						<div>3 DERNIERS VS</div>
					</th>
					<th class="sort sort-index_vs" @click="sort( 'index_vs' )" v-if="!is_phone()">
						<div>IDX</div>
					</th>
					<th class="sort sort-index_def" @click="sort( 'index_def' )" v-if="!is_phone()">
						<div>-/+</div>
					</th>

					<th class="separator" v-if="!is_phone()"></th>

					<th class="sort sort-momentum" @click="sort( 'momentum' )">
						<div>MOM</div>
					</th>
					<th class="sort sort-b2b" @click="sort( 'b2b' )">
						<div>B2B</div>
					</th>
					<th class="sort sort-homeaway" @click="sort( 'homeaway' )" v-if="!is_phone()">
						<div>H/A</div>
					</th>
					<th class="sort sort-stab" @click="sort( 'stab' )" v-if="!is_phone()">
						<div>STAB</div>
					</th>
					<th class="sort-estimation desc sort" @click="sort( 'estimation' )">
						<div>EST</div>
					</th>
					<th class="sort sort-index" @click="sort( 'index' )">
						<div>NF</div>
					</th>

					<th class="separator"></th>

				</tr>

			</thead>

			<tbody><template v-for="( player, key ) in get_players_filtered" :player="player">

				<player-row ref="players"
					v-bind:key="key" :player="player"
					@select_player="select_player"
					@pick_player="refresh_players"
				/>
				<player-row-advanced v-bind:key="'adv-' + key" :player="player"></player-row-advanced>

			</template></tbody>

		</table></template>

		<!-- Version mobile -->
		<template slot="container-body" v-if="is_phone()"><div class="table-mobile">

			<div class="tm-head tm-tr">

				<div class="btn-filters">

					<div class="compare tm-th">

						<button @click="compare = !compare" :class="{ active : compare }">
							<font-awesome-icon :icon="['fa', 'people-arrows']" />
						</button>
						<button @click="available_only = !available_only" :class="{ active : available_only }">
							<font-awesome-icon :icon="['fa', 'user-check']" />
						</button>
						<button @click="available_injury = !available_injury" :class="{ active : available_injury }">
							<font-awesome-icon :icon="['fa', 'user-md']" />
						</button>

					</div>

				</div>

				<div>
					
					<div class="sort-avg_season average score sort tm-th">
						<div>SR</div>
					</div>
					<div class="sort-momentum average sort score tm-th">
						<div>MOM</div>
					</div>
					<div class="sort-estimation average sort score tm-th">
						<div>EST</div>
					</div>
					<div class="sort-index average sort score tm-th">
						<div>NF</div>
					</div>
					<div class="score tm-th"></div>
					
				</div>
			
			</div>

			<div class="tm-body">

				<template v-for="( player, key ) in get_players_filtered" :player="player">
					<player-row-mobile ref="players"
						v-bind:key="key" :player="player"
						@select_player="select_player"
						@pick_player="refresh_players"
					/>
				</template>
				
			</div>
		
		</div></template>

	</container>

</template>

<script>

	import device from '@/mixins/device'
	import Tools from '@/class/Tools.class'

	import { library } from '@fortawesome/fontawesome-svg-core'
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
	import { faPeopleArrows, faTimes, faUserCheck, faUserMd } from '@fortawesome/free-solid-svg-icons'
	library.add( faPeopleArrows, faTimes, faUserCheck, faUserMd )

	export default {
		
		name	: 'players',

		components	: {
		
			vselect	: () => import( '@/components/form/vselect.vue' ),
			container	: () => import( '@/components/container.vue' ),
			
			playerRow	: () => import( '@/views/day/player/desktop.vue' ),
			playerRowAdvanced	: () => import( '@/views/day/player/desktop-advanced.vue' ),
			playerRowMobile	: () => import( '@/views/day/player/mobile.vue' ),

			'font-awesome-icon'	: FontAwesomeIcon,
			
		},

		props	: [ 'players', 'day' ],

		data	: function() { return {

			compare	: false,
			available_only	: false,
			available_injury	: false,

			players_db	: [],	// Base des joueurs
			players_base	: [],	// Joueurs après utilisateurs
			players_filtered	: [],	// Joueurs après filtres

			sorting	: {
				field	: 'estimation',
				dir	: 'asc',	// ASC car ça va switcher au premier chargement
			}, 

		}},

		mixins	: [ device ],

		methods	: {

			/**
			 * Construction du tableau des joueurs au chargement des données
			 */

			set_players	: function() {

				// console.log( 'set_players' )

				var players	= []

				this.players_filtered	= []
				
				this.$store.state.players.results.forEach(function( player ) {

					players.push({ ...{
						visible	: true,
						availability	: true,
						today_pick	: false,
						selected	: false,
						advanced	: false,
					}, ...player })

				})

				this.players_db	= players
				this.players_base	= players

				this.set_players_spec()
				// this.set_injury_status()

			},

			/**
			 */

			set_players_spec	: function() {

				// console.log( 'set_players_spec' )

				var _this	= this
				var today	= this.$parent.day
				var user_picks	= this.$store.state.user.picks

				this.players_base	= []
				this.players_filtered	= []

				this.players_db.forEach(function( player_db ) {

					var player	= { ...{}, ...player_db }
					var availability	= true
					var today_pick	= false

					if ( user_picks.length > 0 ) {
						
						user_picks.filter(function( pick ) {
							
							if ( pick.player_id == player_db.id ) {
								
								/**
								 * Disponibilité du joueur
								 */
								
								var pick_date	= _this.$moment( pick.date + ' 00:00:00' )
								var diff	= 30 - today.diff( pick_date, 'days' )

								if ( diff > 0 ) {
									availability	= diff
								}

								/**
								 * Pick du jour
								 */

								if ( pick_date.valueOf() == today.valueOf() ) {
									today_pick	= true
								}

							}
							
						})

					}

					player.availability	= availability
					player.today_pick	= today_pick

					var player_final	= { ...{

						visible	: true,
						selected	: false,
						advanced	: false,
						injury	: {
							class	: 'notready',
							details	: 'Non défini',
						},

					}, ...player }

					_this.players_base.push( player_final )
					_this.players_filtered.push( player_final )

				})

				// this.filter_players()

			},

			/**
			 * Préparation du tri
			 */

			sort	: function( field ) {

				var dir	= 'desc'

				Tools.remove_class( '.sort', this.sorting.dir )

				if ( field === this.sorting.field ) {
					dir	= ( this.sorting.dir === 'desc' ) ? 'asc' : 'desc'
				}

				Tools.add_class( '.sort-' + field, dir )



				var players	= this.players_filtered.slice()
				this.players_filtered	= []
				
				this.players_filtered	= players.sort( ( a , b ) => {

					var current	= field
					var a_val	= a.sorting_values[current]
					var b_val	= b.sorting_values[current]
					
					if ( current != 'team' ) {
						a_val	= parseFloat( a.sorting_values[current] )
						b_val	= parseFloat( b.sorting_values[current] )
					}

					let modifier	= 1

					if ( dir === 'desc' ) modifier = -1

					if ( a_val < b_val ) return -1 * modifier
					if ( a_val > b_val ) return 1 * modifier

					return 0

				})

				this.sorting	= {
					field	: field,
					dir	: dir,
				}

			},

			/**
			 * Préparation du tri (mobile)
			 */

			sort_mobile	: function( event ) {

				/**
				 * Si pas de target, c'est la select qui a été modifié, sinon c'est l'ordre ASC/DESC (donc on ne change rien)
				 */

				this.sort( !event.target ? event.value : this.sorting.field )

			},

			/**
			 * Filtrage des joueurs selon : 
			 * 
			 * - La disponibilité pour l'utilisateur
			 * - La sélection par l'utilisateur
			 * - La disponibilité (blessure)
			 */

			filter_players	: function() {

				var _this	= this

				/** Reset liste des joueurs */
				this.players_filtered	= this.players_base



				/**
				 * Filtrage joueurs sélectionnés pour la comparaison
				 */

				if ( this.compare ) {

					this.players_filtered	= this.players_filtered.filter( player => player.selected )

				}



				/**
				 * Filtrage joueurs disponibles
				 */

				if ( _this.available_only ) {

					this.players_filtered	= this.players_filtered.filter( player => ( player.availability === true || player.today_pick === true ) )

				}

				this.filter_injuries()

			},

			/**
			 * Filtrage suivant les blessures
			 */

			filter_injuries	: function() {

				if ( this.available_injury ) {

					this.players_filtered	 = this.players_filtered.filter( player => ( player.injury && player.injury.class != 'out' ) )

				}

			},

			/**
			 * Sélection d'un joueur (pour la comparaison)
			 */

			select_player	: function( player_id, selected ) {

				var _this	= this

				this.players_filtered.forEach(function( player, key ) {

					if ( player_id == player.id ) {
						_this.players_filtered[key].selected	= selected
					}

				})

			},

			/**
			 */

			refresh_players	: function() {

				this.set_players()
				this.filter_players()

			},

			/**
			 * Récupération et définition du statut (blessure) des joueurs de la liste
			 */
			
			// set_injury_status() {

			// 	var _this	= this
			// 	var injury_api_error	= false

			// 	var timeout	= 0
			// 	var api_blocked	= false

			// 	this.players_db.forEach(function( player, key ) {

			// 		window.setTimeout( function() {

			// 			if ( api_blocked === true )	return false

			// 			/**
			// 			 * Il y a eu une erreur précédemment
			// 			 * On arrête (blocage temporaire probable)
			// 			 */

			// 			if ( injury_api_error === true ) {

			// 				api_blocked	= true
			// 				console.error( "Récupération injury status : Blocage API." )
			// 				return false
							
			// 			}

			// 			injury_api_error	= false

			// 			api.get( 'player/injury_status', {
			// 				id	: player.id,
			// 			}).then(( json ) => {

			// 				if ( json.api_error ) {

			// 					/** Indisponibilité ou blocage temporaire */
			// 					if ( Object.keys(json.api_error).length == 0 || json.api_error.indexOf( 'json_decode error' ) > -1 ) {
			// 						injury_api_error	= true
			// 					}
				
			// 				} else {

			// 					/**
			// 					 * Out for the season
			// 					 */

			// 					if ( json.formated == 'ofs' ) {
									
			// 						_this.players_base.splice( key, 1 )

			// 					} else {

			// 						var injury	= {
			// 							class	: json.formated,
			// 							details	: json.details,
			// 						}

			// 						_this.players_db[key].injury	= injury
			// 						_this.players_base[key].injury	= injury

			// 						_this.filter_injuries()

			// 					}
				
			// 				}

			// 			})

			// 			if ( process.env.NODE_ENV == 'development' && key == 9 )	api_blocked = true

			// 		}, timeout )

			// 		timeout	+= 350

			// 	})
				
			// },

		},

		watch	: {

			/**
			 * Actions une fois les données des joueurs chargées
			 */

			players_from_db	: function() {
				this.set_players()
			},

			/**
			 * Surveille les picks de l'utilisateur en cours afin de définir les disponibilités
			 * 
			 * Utilisé qu'au 1er chargement
			 */

			user_picks	: function() {
				this.set_players_spec()
			},

			/**
			 */

			user_id	: function() {
				this.set_players_spec()
			},

			/**
			 */

			compare	: function() {
				this.filter_players()
			},

			/**
			 */
			
			available_only	: function() {
				this.filter_players()
			},

			/**
			 */
			
			available_injury	: function() {
				this.filter_players()
			},

			/**
			 */

			players_db	: function() {
				this.set_players_spec()
			},

		},

		computed	: {

			/**
			 */

			players_from_db	: function() {
				return this.$store.state.players.results
			},

			/**
			 */

			user_id	: function() {
				return this.$store.state.user.id
			},

			/**
			 */

			user_picks() {
				return this.$store.state.user.picks
			},

			/**
			 */

			user_day_pick() {

				var _this	= this

				return this.user_picks.find(function( pick ) {
					return pick.date == _this.day.format( 'YYYY-MM-DD' )
				})

			},

			/**
			 */

			get_players_filtered	: function() {
				return this.players_filtered
			},

		},

		mounted	: function() {

			/**
			 * Rebuild si résultats déjà récupérés
			 */

			if ( this.$store.state.players.results.length > 0 ) {
				this.set_players()
			}

		},

	}

</script>

<style lang="scss" scoped>

	@import '@/assets/scss/_base';

	@import '@/assets/scss/datas-table';
	@import '@/assets/scss/table-mobile';



	/** ---------------------------------------*\	
	*---------------------------------------- **/

	#players::v-deep .container-head {

		.head-actions {

			max-width: 40px;

			button {
				margin: 0;
			}

		}

		.vselect {
			margin-left: 50px;
		}

		.mobile-ordering {

			@include flex_display;
			margin-left: 25px;

			button, .vselect {
				height: 30px;
			}

			button {
				background-color: #000;
				color: #FFF;
				border-right: 0;
			}

			.vselect {

				margin: 0;
				margin-left: -2px;

				.vselect-selector {
					padding: 5px 10px;
				}

			}

		}

	}

	/** ---------------------------------------*\	
	*---------------------------------------- **/

	#app.device-phone #players::v-deep .container-head > div:not(.head-actions) {

		display: flex;
		justify-content: flex-start;
		align-items: center;

	}

	/** ---------------------------------------*\
		Main container
	*---------------------------------------- **/

	#players::v-deep .container-body {

		padding: 0;

		/** ---------------------------------------*\
		*---------------------------------------- **/
		
		.scores-list {

			display: flex;
			justify-content: flex-start;
			max-width: none;
			width: 135px;
			padding: 0;
			margin: 0;

			@include mobile() {
				width: 100%;
			}

			& > .score {

				position: relative;
				width: 40px;
				padding: 0;
				// margin: 0 2.5px;
				margin: 0;
				height: 40px;
				line-height: 40px;
				text-align: center;

				@include mobile() {
					margin: 0;
				}

			}

			.ttfl-score {
				font-size: 0.85em;
			}

		}

	}

	/** ---------------------------------------*\
	*---------------------------------------- **/

	#players::v-deep .table-mobile .tm-head {

		justify-content: space-between;

		& > div {
			@include flex_display;
		}

		div.compare {

			padding-right: 0;

			@include iphone5() {
				max-width: 120px;
			}

		}

		div.compare button {

			margin-right: 10px;

			@include iphone5() {
				padding-left: 8px;
				padding-right: 8px;
				margin-right: 5px;
			}

			&:last-child {
				margin-right: 0;
			}

			&.active {
				background: #000;
				color: #FFF;
			}

		}

		div.tm-th.score {

			@include flex_display;
			justify-content: center;
			width: 40px;
			height: 100%;
			padding: 0;
			
		}

	}

</style>
